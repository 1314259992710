<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="tournament-info-breadcrumb">
      <div class="mt-1 row text-center">
        <div class="col-12 text-start">
          <a class="cursor-pointer" @click="$router.push({ name: 'AccountSettings' })">アカウント設定</a> <i
            class="fa fa-angle-right"></i> アカウント削除
        </div>
      </div>
    </div>

    <div class="card-body deleteaccount-card-body">
      <div class="col-6 mx-auto mt-5 mb-5">
        <div class="row">
          <div class="col-12">
            <label for="inputAccountDeletion">アカウント削除</label>
          </div>
          <div class="col-12">
            <label for="inputCurrentPassword">現在のパスワード</label>
            <input id="inputCurrentPassword" v-model="password" class="form-control" name="inputCurrentPassword"
                   type="password">
            <div v-show="errors['authenticationPassword']" id="err_password" class="form-error">
              {{ errors['authenticationPassword'] }}
            </div>
          </div>
          <div class="col-12">
            <p class="perform-account-deletion">アカウントに紐づいているすべての情報が削除され、この操作は後戻りできません。<br><br>アカウントの削除を実行します。<br>よろしいですか？
            </p>
          </div>
        </div>
        <div class="row mt-3 mb-5">
          <div class="col">
            <button class="btn w-100 mw-100 btn-light custom-btn border-0" v-on:click="doCancel">キャンセル</button>
          </div>
          <div class="col">
            <button class="btn w-100 mw-100 btn-dark custom-btn col-6" v-on:click="doDelete">削除</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import Common from '../assets/jsadminclient/common'
import {SetAdminDeleteUser} from "../assets/jsadminclient/index";

export default {
  name: "DeleteAccount",
  data() {
    return {
      password: '',
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    /**
     * Validation and registration of input values
     * 1,2,3 is check
     *
     * @param commit
     * @param {Credentials} payload
     * @returns {Promise<Credentials>}
     */
    async doDelete(step) {
      this.errors = [];

      const userId = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const params = new SetAdminDeleteUser()
        params.authenticationPassword = this.password
        const jsonObject = await api.usersUserIdDeletePostAsync(userId, params)

        Common.showToast("アカウントが削除されました。")
        this.$router.push({name: 'Login'})

      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async doCancel(step) {
      this.$router.push({name: 'AccountSettings'})
    },
  },
}
</script>

<style scoped>

.container {
  color: #333333;
  font-size: 16px;
}

label[for="inputAccountDeletion"] {
  font-size: 30px;
  font-weight: bold;
  line-height: 66px;
}

label[for="inputCurrentPassword"] {
  line-height: 30px;
}

.custom-btn {
  height: 44px;
}

.btn-light {
  background: #FFFFFF;
}

.btn-light:focus {
  background: #FFFFFF;
}

input {
  background-color: #F2ECF0;
  height: 48px;
}

.perform-account-deletion {
  padding-top: 20px;
}

.tournament-info-breadcrumb {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 17px;
  position: relative;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #D3D8EA;
}

.tournament-info-breadcrumb a {
  color: #333333;
}

.deleteaccount-card-body {
  padding: 0px;
}

.content {

&
.deleteaccount {

.form-control {
  border-style: none;
}

}
}
</style>
